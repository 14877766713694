export const drawRectangle = (
    pdf,
    color,
    distanceFromLeft,
    distanceFromTop,
    width,
    height
  ) => {
    return (
      pdf.setFillColor(...color),
      pdf.rect(distanceFromLeft, distanceFromTop, width, height, "F")
    );
  };
export const doHeader = (
    pdf,
    text,
    textMargin,
    orangeColor,
    whiteColor,
    distanceFromLeft,
    distanceFromTop,
    cellWidth,
    cellHeight
  ) => {
    return (
      pdf.setFillColor(...orangeColor),
      pdf.setTextColor(...whiteColor),
      pdf.setFontSize(12),
      pdf.rect(distanceFromLeft, distanceFromTop, cellWidth, cellHeight, "F"),
      pdf.text(
        `${text}`,
        distanceFromLeft + textMargin,
        distanceFromTop + cellHeight / 2,
        {
          align: "center",
          baseline: "middle",
        }
      )
    );
  };
 export const doData = (
    pdf,
    text,
    fontSize,
    rowIndex,
    whiteColor,
    blackColor,
    distanceFromLeft,
    distanceFromTop,
    cellWidth,
    cellHeight,
    center
  ) => {
    return (
      pdf.setFillColor(...whiteColor),
      pdf.setTextColor(...blackColor), // Color de texto negro
      pdf.setFontSize(fontSize),
      pdf.rect(
        distanceFromLeft,
        distanceFromTop + (rowIndex + 1) * cellHeight,
        cellWidth,
        cellHeight,
        "F"
      ),
      pdf.text(
        text,
        center === true ? distanceFromLeft + cellWidth / 2 : distanceFromLeft,
        distanceFromTop + (rowIndex + 1.5) * cellHeight,
        { align: "center", baseline: "middle" }
      )
    );
  };
 export const writeText = (
    pdf,
    text,
    size,
    fontColor,
    bgColor,
    distanceFromLeft,
    yPosition,
    align
  ) => {
    pdf.setFillColor(...bgColor);
    pdf.setTextColor(...fontColor); // Color de texto negro
    pdf.setFontSize(size);
    align
      ? pdf.text(text, distanceFromLeft, yPosition, { align: align })
      : pdf.text(text, distanceFromLeft, yPosition);
  };