import {
  checkDateDay,
  dateToAMD,
  dateToMDA,
  getDate,
  isMonday,
  isSunday,
} from "../helpers/general";
import { useEffect, useReducer, useState } from "react";
import MyButton from "./MyButton";
import useQuery from "../hooks/useQuery";
import { Alert, CircularProgress, TextField } from "@mui/material";
import Navbar from "./Navbar";
import TopBar from "./TopBar";
import DatePick from "./DatePick";
import { InputReducer } from "../reducers/InputReducer";
import jsPDF from "jspdf";
import { footerData, totalTitles } from "../helpers/testData";
import {
  doData,
  doHeader,
  drawRectangle,
  writeText,
} from "../helpers/invoicesFunction";

const FullInvoice = () => {
  const [invoiceType, setInvoiceType] = useState("week");
  const [projectCheckboxes, setProjectCheckboxes] = useState({});

  const facturaReducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_DATA":
        return {
          data: action.payload,
        };

      default:
        return state;
    }
  };
  const [invoiceNumber, setInvoiceNumber] = useState("");

  const [facturaData, facturaDispatch] = useReducer(facturaReducer, {
    data: null,
  });

  const [range, setRange] = useState({ date_start: null, date_end: null });
  const [sending, setSending] = useState(false);
  const [projects, setProjects] = useState([]);
  const { getData, postData } = useQuery();
  const [projectsData, dispatch] = useReducer(InputReducer, {});
  const [invoices, setInvoices] = useState();

  let projectsToInvoice = [];
  let projectTitles = [];
  const [error, setError] = useState({
    status: false,
    message: "",
    severity: "",
  });

  const handleInvoiceType = (e) => {
    setInvoiceType(e.target.value);
  };
  const setStart = (e) => {
    const newDate = dateToAMD(e.$d);
    setRange({ ...range, date_start: newDate });
  };

  const setEnd = (e) => {
    const newDate = dateToAMD(e.$d);
    setRange({ ...range, date_end: newDate });
  };
  const setProjectsArray = (e) => {
    if (e.target.checked) {
      projectsToInvoice.push(e.target.id);
    } else {
      const index = projectsToInvoice.indexOf(e.target.id);
      if (index !== -1) {
        projectsToInvoice.splice(index, 1);
      }
    }
  };
  const getProjectsToCheck = async () => {
    setProjects([]);
    setError({ error: false, severity: "", message: "" });

    let endpoint;
    if (invoiceType === "day") {
      if (!range.date_start) {
        setError({
          status: true,
          severity: "error",
          message: "You must select a date",
        });
        return;
      }
      endpoint = `invoiceDailyprojects/${range.date_start}`;
    } else {
      endpoint = `invoiceWeeklyprojects/${range.date_start}/${range.date_end}`;
      if (!range.date_start || !range.date_end) {
        setError({
          status: true,
          severity: "error",
          message: "You must select a date range",
        });
        return;
      }
      const mondayTrue = isMonday(range.date_start);
      const sundayTrue = isSunday(range.date_end);
      if (!mondayTrue || !sundayTrue) {
        setError({
          status: true,
          message:
            "Start date must be a monday and end date must be a sunday, please check",
          severity: "error",
        });
        return;
      }
    }

    setProjects([]);

    const projects = await getData(endpoint, localStorage.token);
    if (projects.data.status === 201) {
      setError({
        status: true,
        message: "There's no projects to invoice",
        severity: "error",
      });
      setSending(false);

      return;
    }
    setProjects(projects.data.data);
    // return FullAttendances;
  };

  const getInvoices = async () => {
    let body;
    let endpoint;
    projectsToInvoice = Object.keys(projectCheckboxes).filter(
      (clave) => projectCheckboxes[clave]
    );
    setProjectCheckboxes({});
    if (invoiceType === "day") {
      endpoint = "invoiceDaily";
      body = {
        date_invoice: range.date_start,
        id_project: projectsToInvoice,
        invoice_number: invoiceNumber,
      };
    } else {
      endpoint = "invoiceWeekly";
      body = {
        date_start: range.date_start,
        date_end: range.date_end,
        id_project: projectsToInvoice,
        invoice_number: invoiceNumber,
      };
    }

    const invoicesToPrint = await postData(endpoint, body, localStorage.token);
    facturaDispatch({
      type: "CHANGE_DATA",
      payload: invoicesToPrint.data.data.data,
    });
  };

  const mapProjectsToPDF = (fact) => {
    const mainArray = Object.values(facturaData.data)[0];

    projectTitles = Object.keys(mainArray.projects);
    const projects = Object.values(mainArray.projects);
    projects.map((project, index) => {
      generatePDF(project, index);
    });
  };
  const selectAllProjects = (e) => {
    const newProjectCheckboxes = {};
    for (const project of projects) {
      newProjectCheckboxes[project.id] = e.target.checked;
    }
    setProjectCheckboxes(newProjectCheckboxes);
  };

  const generatePDF = (project, index) => {
    // Crear un nuevo objeto jsPDF

    const pdf = new jsPDF("p", "mm", "a4", true);

    // Configuración del tamaño de texto y colores y ancho
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const sideMargin = 10;
    pdf.setFontSize(12);
    const orangeColor = [248, 112, 53];
    const whiteColor = [255, 255, 255];
    const blackColor = [0, 0, 0];
    const lighterOrange = [255, 224, 204];
    const darkenOrange = [255, 179, 128];
    // Agregar imagen (logo) en el lado izquierdo
    const logoImg = new Image();
    logoImg.src = "../assets/logo.png";
    const logoWidth = 43;
    const logoHeight = 20;

    pdf.addImage(logoImg, "PNG", sideMargin, 5, logoWidth, logoHeight); // Ajusta las coordenadas y el tamaño según sea necesario
    let currentPage = 1;

    // Lado derecho dividido en bloques
    // Bloque superior con la palabra "INVOICE"
    pdf.setTextColor(...orangeColor);
    pdf.setFontSize(20);
    //********************************************INVOICE TITlE****************************************************////
    const invoiceText = "INVOICE";
    const invoiceTextWidth =
      (pdf.getStringUnitWidth(invoiceText) * pdf.internal.getFontSize()) /
      pdf.internal.scaleFactor;
    const invoiceTextX = pageWidth - invoiceTextWidth - sideMargin;
    pdf.text(invoiceText, invoiceTextX, 10); // Ajusta las coordenadas según sea necesario
    // Bloque inferior con el table header
    const tableHeaderX = pageWidth / 2; // X inicial del table header
    const tableHeaderY = 13; // Y inicial del table header
    const cellWidth = pageWidth / 2 / 2 - sideMargin / 2;
    const cellHeight = 8;

    //***************************************************INVOICE #****************************************************////

    doHeader(
      pdf,
      "INVOICE #",
      25,
      orangeColor,
      whiteColor,
      tableHeaderX,
      tableHeaderY,
      cellWidth,
      cellHeight - 2
    );

    doHeader(
      pdf,
      "DATE",
      25,
      orangeColor,
      whiteColor,
      tableHeaderX + cellWidth,
      tableHeaderY,
      cellWidth,
      cellHeight - 2
    );
    const date = getDate();
    // Datos de la tabla
    const invoiceData = [
      { invoiceNumber: project.invoice_number.toString(), date },
    ];

    // Configuración de estilos para la data de la tabla
    pdf.setFillColor(...whiteColor);
    pdf.setTextColor(0, 0, 0); // Color de texto negro
    pdf.setFontSize(10);

    // Dibujar las celdas de la data de la tabla

    invoiceData.forEach((row, rowIndex) => {
      doData(
        pdf,
        row.invoiceNumber,
        12,
        rowIndex,
        whiteColor,
        blackColor,
        tableHeaderX,
        tableHeaderY,
        cellWidth,
        cellHeight - 2,
        true
      );
      doData(
        pdf,
        row.date,
        12,
        rowIndex,
        whiteColor,
        blackColor,
        tableHeaderX + cellWidth,
        tableHeaderY,
        cellWidth,
        cellHeight - 2,
        true
      );
    });
    //***************************************************BILL TO****************************************************////

    //params para doHeader (pdf, texto, margen de izq de texto, color fondo, color letra, coord X, coord Y, ancho, altura)

    doHeader(
      pdf,
      "BILL TO",
      15,
      orangeColor,
      whiteColor,
      sideMargin,
      logoHeight + 8,
      pageWidth / 2 - 20,
      cellHeight - 2
    );

    doHeader(
      pdf,
      "CUSTOMER ID",
      25,
      orangeColor,
      whiteColor,
      tableHeaderX,
      logoHeight + 8,
      cellWidth,
      cellHeight - 2
    );

    doHeader(
      pdf,
      "TERMS",
      25,
      orangeColor,
      whiteColor,
      tableHeaderX + cellWidth,
      logoHeight + 8,
      cellWidth,
      cellHeight - 2
    );

    ///
    const companyInfo = {
      name: "First Onsite",
      address_1: "3401 Quorum Drive, Suite 300",
      address_2: "Fort Worth, TX 76137",
      email: "Ap@firstonsite.com",
    }; // Agrega más filas según sea necesario
    const companyInfoArray = Object.values(companyInfo);
    const fontSize = 9;
    companyInfoArray.map((dato, index) => {
      pdf.setFillColor(...whiteColor);
      pdf.setTextColor(...blackColor); // Color de texto negro
      pdf.setFontSize(fontSize);
      const yPosition = logoHeight + cellHeight * 2.4 + index * 6;
      pdf.text(dato, sideMargin + 5, yPosition);
    });
    const customerData = { CustomerId: "MDW", terms: "Due Upon Receipt" };
    const customerDataArray = Object.values(customerData);
    customerDataArray.map((dato, index) => {
      doData(
        pdf,
        dato,
        9,
        0,
        whiteColor,
        blackColor,
        tableHeaderX + index * cellWidth,
        logoHeight + 5,
        cellWidth,
        cellHeight+2,
        true
      );
    });

    //*********************************************DESCRIPTION HEADER************************************************////

    const qtyMargin = pageWidth / 2;
    const qtyWidth = pageWidth / 2 / 4;
    const unitPriceMargin = qtyMargin + qtyWidth;
    const unitPriceWidth = pageWidth / 2 / 3;
    //DESCRIPTION
    doHeader(
      pdf,
      "DESCRIPTION",
      25,
      orangeColor,
      whiteColor,
      sideMargin,
      pageHeight / 4.8,
      pageWidth / 2,
      cellHeight -2
    );
    //QTY
    doHeader(
      pdf,
      "QTY",
      10,
      orangeColor,
      whiteColor,
      qtyMargin,
      pageHeight / 4.8,
      qtyWidth,
      cellHeight -2
      ,
      true
    );
    //UNIT PRICE
    doHeader(
      pdf,
      "UNIT PRICE",
      15,
      orangeColor,
      whiteColor,
      unitPriceMargin,
      pageHeight / 4.8,
      unitPriceWidth,
      cellHeight -2
      ,
      true
    );
    // AMOUNT
    doHeader(
      pdf,
      "AMOUNT",
      15,
      orangeColor,
      whiteColor,
      unitPriceMargin + unitPriceWidth,
      pageHeight / 4.8,
      unitPriceWidth,
      cellHeight -2
      ,
      true
    );
    const projectDataTitles = [
      "Project Name:",
      "Project Number:",
      `${invoiceType === "day" ? "Date:" : "Week:"}`,
    ];
    projectDataTitles.map((dato, index) => {
      pdf.setFillColor(...whiteColor);
      pdf.setTextColor(...blackColor); // Color de texto negro
      pdf.setFontSize(9);
      const yPosition = pageHeight / 4.8 + cellHeight * 1.5 + index * (cellHeight-2);
      pdf.text(dato, sideMargin + 5, yPosition);
    });
    const projectTitleData = projectTitles[index].split("+");
    let projectDataArray;

    if (invoiceType === "week") {
      projectDataArray = [
        projectTitleData[0],
        projectTitleData[1],
        `${dateToMDA(range.date_start)} to ${dateToMDA(range.date_end)}`,
      ];
    } else {
      projectDataArray = [
        projectTitleData[0],
        projectTitleData[1],
        `${dateToMDA(range.date_start)}`,
      ];
    }

    projectDataArray.map((dato, index) => {
      pdf.setFillColor(...whiteColor);
      pdf.setTextColor(...blackColor); // Color de texto negro
      pdf.setFontSize(8);
      const yPosition = pageHeight / 4.8 + cellHeight * 1.5 + index * (cellHeight -2);
      pdf.text(dato, pageWidth / 5, yPosition);
    });

    //*********************************************SKILLTECHNICIAN HOURS************************************************////

    //*********************************************SKILLTECHNICIAN DATA************************************************////
    let stDataArray,
      glDataArray,
      stOverTimeArray,
      glOverTimeArray = [];
    if (project.ST) {
      stDataArray = Object.values(project.ST.entries);
      stOverTimeArray = Object.entries(stDataArray)
        .filter(([key, value]) => value.overtime > 0)
        .map(([key, value]) => ({
          overtime: value.overtime,
          name_employee: value.name_employee,
        }));
    }
    if (project.GL) {
      glDataArray = Object.values(project.GL.entries);
      glOverTimeArray = Object.entries(glDataArray)
        .filter(([key, value]) => value.overtime > 0)
        .map(([key, value]) => ({
          overtime: value.overtime,
          name_employee: value.name_employee,
        }));
    }

    let stInitialYPosition = pageHeight / 4 + 26;
    let yPosition = stInitialYPosition;
    let printingItem = 1;
    if (project.ST && stDataArray?.length > 0) {
      drawRectangle(
        pdf,
        darkenOrange,
        sideMargin,
        pageHeight / 4.8 + 28,
        pageWidth - sideMargin * 2,
        cellHeight - 2
      );

      writeText(
        pdf,
        "Skill Technician hours:",
        10,
        blackColor,
        whiteColor,
        sideMargin + 5,
        pageHeight / 4.8 + 32,
      );
      writeText(
        pdf,
        "Hours",
        10,
        blackColor,
        whiteColor,
        (3.3 * pageWidth) / 8,
        pageHeight / 4.8 + 32,

      );
      writeText(
        pdf,
        project.ST.hours.toFixed(2).toString(),
        10,
        blackColor,
        whiteColor,
        qtyMargin + 15,
        pageHeight / 4.8 + 32,

      );
      writeText(
        pdf,
        "35",
        10,
        blackColor,
        whiteColor,
        unitPriceMargin + 15,
        pageHeight / 4.8 + 32,

      );
      writeText(
        pdf,
        project.ST.hours_amount
          .toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          .toString(),
        10,
        blackColor,
        whiteColor,
        unitPriceMargin + unitPriceWidth + 10,
        pageHeight / 4.8 + 32,

      );
      stDataArray.map((employee) => {
        if (currentPage === 1 && printingItem > 23) {
          currentPage++;
          printingItem = 1;
          pdf.addPage();
        } else if (printingItem > 34) {
          currentPage++;
          printingItem = 1;
          pdf.addPage();
        }
        if (currentPage === 1) {
          yPosition = stInitialYPosition + (printingItem - 1) * cellHeight;
        } else {
          yPosition = 18 + (printingItem - 1) * cellHeight;
        }

        let regulartime = employee.regulartime.toFixed(2).toString();
        //  let hoursAmount = project.ST.hours_amount.toString();

        writeText(
          pdf,
          employee.name_employee,
          8,
          blackColor,
          whiteColor,
          pageWidth / 5,
          yPosition
        );
        writeText(
          pdf,
          regulartime,
          10,
          blackColor,
          whiteColor,
          (3.3 * pageWidth) / 7 - 3,
          yPosition,
          "right"
        );
        printingItem++;
      });

      // Totales de ST Standard
      yPosition = yPosition + 8; // para bajar el titulo siguiente
      drawRectangle(
        pdf,
        lighterOrange,
        sideMargin,
        yPosition - 5,
        pageWidth - sideMargin * 2,
        cellHeight
      );
      writeText(
        pdf,
        "Total ST",
        10,
        blackColor,
        whiteColor,
        pageWidth / 5,
        yPosition
      );
      writeText(
        pdf,
        project.ST.hours.toFixed(2).toString(),
        10,
        blackColor,
        whiteColor,
        (3.3 * pageWidth) / 7 - 3,
        yPosition,
        "right"
      );
      printingItem = printingItem + 1; // le sumo ese titulo
    }
    //*********************************************OVER TIME************************************************////
    let stOverTotalInitialPos = yPosition;

    if (project.ST && stOverTimeArray.length > 0) {
      let stOverInitialPos = yPosition + 8; //16 son dos celdas de alto
      drawRectangle(
        pdf,
        darkenOrange,
        sideMargin,
        stOverInitialPos - 5,
        pageWidth - sideMargin * 2,
        cellHeight
      );

      writeText(
        pdf,
        "Overtime ST",
        10,
        blackColor,
        whiteColor,
        pageWidth / 5,
        stOverInitialPos
      );
      writeText(
        pdf,
        "Hours",
        10,
        blackColor,
        whiteColor,
        (3.3 * pageWidth) / 8,
        stOverInitialPos
      );
      writeText(
        pdf,
        project.ST.hours_overtime.toFixed(2).toString(),
        10,
        blackColor,
        whiteColor,
        qtyMargin + 15,
        stOverInitialPos
      );
      writeText(
        pdf,
        "52.50",
        10,
        blackColor,
        whiteColor,
        unitPriceMargin + 15,
        stOverInitialPos
      );
      writeText(
        pdf,
        project.ST.hours_overtime_amount
          .toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          .toString(),
        10,
        blackColor,
        whiteColor,
        unitPriceMargin + unitPriceWidth + 10,
        stOverInitialPos
      );

      printingItem = printingItem + 1; // le sumo ese anterior

      stOverTimeArray.map((employee) => {
        if (currentPage === 1 && printingItem > 21) {
          currentPage++;
          printingItem = 1;
          pdf.addPage();
        } else if (printingItem > 34) {
          currentPage++;
          printingItem = 1;
          pdf.addPage();
        }
        if (currentPage === 1) {
          yPosition = stInitialYPosition + (printingItem - 1) * cellHeight;
        } else {
          yPosition = 18 + (printingItem - 1) * cellHeight;
        }

        printingItem = printingItem + 1;

        writeText(
          pdf,
          employee.name_employee,
          8,
          blackColor,
          whiteColor,
          pageWidth / 5,
          yPosition
        );
        writeText(
          pdf,
          employee.overtime.toFixed(2).toString(),
          10,
          blackColor,
          whiteColor,
          (3.3 * pageWidth) / 7 - 3,
          yPosition,
          "right"
        );
      });
      stOverTotalInitialPos = yPosition + 8;
      drawRectangle(
        pdf,
        lighterOrange,
        sideMargin,
        stOverTotalInitialPos - 5,
        pageWidth - sideMargin * 2,
        cellHeight
      );
      writeText(
        pdf,
        "Total OverTime ST",
        10,
        blackColor,
        whiteColor,
        pageWidth / 5,
        stOverTotalInitialPos
      );

      writeText(
        pdf,
        project.ST.hours_overtime.toFixed(2).toString(),
        10,
        blackColor,
        whiteColor,
        (3.3 * pageWidth) / 7 - 3,
        stOverTotalInitialPos,
        "right"
      );
      printingItem = printingItem + 1; // sumo el titulo anterior
      yPosition = stOverTotalInitialPos; // cambioaqui
    }
    //*********************************************GL HOURS************************************************/ ///
    let globalInitialPos, glTotalPosition;

    if (project.ST && stDataArray?.length > 0) {
      globalInitialPos = yPosition + 8;
    } else {
      globalInitialPos = yPosition;
    }
    if (project.GL && glDataArray?.length > 0) {
      drawRectangle(
        pdf,
        darkenOrange,
        sideMargin,
        globalInitialPos - 5,
        pageWidth - sideMargin * 2,
        cellHeight
      );

      writeText(
        pdf,
        "GL hours:",
        10,
        blackColor,
        whiteColor,
        sideMargin + 5,
        globalInitialPos
      );
      writeText(
        pdf,
        "Hours",
        10,
        blackColor,
        whiteColor,
        (3.3 * pageWidth) / 8,
        globalInitialPos
      );
      writeText(
        pdf,
        project.GL.hours.toFixed(2).toString(),
        10,
        blackColor,
        whiteColor,
        qtyMargin + 15,
        globalInitialPos
      );
      writeText(
        pdf,
        "25",
        10,
        blackColor,
        whiteColor,
        unitPriceMargin + 15,
        globalInitialPos
      );
      writeText(
        pdf,
        project.GL.hours_amount
          .toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          .toString(),
        10,
        blackColor,
        whiteColor,
        unitPriceMargin + unitPriceWidth + 10,
        globalInitialPos
      );

      printingItem = printingItem + 1; // sumo el titulo anterior

      glDataArray.map((employee) => {
        if (currentPage === 1 && printingItem > 20) {
          currentPage++;
          printingItem = 1;
          pdf.addPage();
        } else if (printingItem > 34) {
          currentPage++;
          printingItem = 1;
          pdf.addPage();
        }
        if (currentPage === 1) {
          yPosition = stInitialYPosition + (printingItem - 1) * cellHeight;
        } else {
          yPosition = 18 + (printingItem - 1) * cellHeight;
        }

        printingItem = printingItem + 1;

        writeText(
          pdf,
          employee.name_employee,
          8,
          blackColor,
          whiteColor,
          pageWidth / 5,
          yPosition
        );
        writeText(
          pdf,
          employee.regulartime.toFixed(2).toString(),
          10,
          blackColor,
          whiteColor,
          (3.3 * pageWidth) / 7 - 3,
          yPosition,
          "right"
        );
      });
      glTotalPosition = yPosition + 8;
      drawRectangle(
        pdf,
        lighterOrange,
        sideMargin,
        glTotalPosition - 5,
        pageWidth - sideMargin * 2,
        cellHeight
      );

      writeText(
        pdf,
        "Total GL",
        10,
        blackColor,
        whiteColor,
        pageWidth / 5,
        glTotalPosition
      );
      writeText(
        pdf,
        project.GL.hours.toFixed(2).toString(),
        10,
        blackColor,
        whiteColor,
        (3.3 * pageWidth) / 7 - 3,
        glTotalPosition,
        "right"
      );
      printingItem = printingItem + 1; // sumo el titulo anterior
    }
    //*********************************************GL OT HOURS************************************************/ ///

    let glOverPosition = glTotalPosition + 8;
    if (project.GL && glOverTimeArray.length > 0) {
      drawRectangle(
        pdf,
        darkenOrange,
        sideMargin,
        glOverPosition - 5,
        pageWidth - sideMargin * 2,
        cellHeight
      );
      writeText(
        pdf,
        "GL Overtime",
        10,
        blackColor,
        whiteColor,
        sideMargin + 5,
        glOverPosition
      );
      writeText(
        pdf,
        "Hours",
        10,
        blackColor,
        whiteColor,
        (3.3 * pageWidth) / 8,
        glOverPosition
      );
      writeText(
        pdf,
        project.GL.hours_overtime.toFixed(2).toString(),
        10,
        blackColor,
        whiteColor,
        qtyMargin + 15,
        glOverPosition
      );
      writeText(
        pdf,
        "37.5",
        10,
        blackColor,
        whiteColor,
        unitPriceMargin + 15,
        glOverPosition
      );
      writeText(
        pdf,
        project.GL.hours_overtime_amount
          .toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          .toString(),
        10,
        blackColor,
        whiteColor,
        unitPriceMargin + unitPriceWidth + 10,
        glOverPosition
      );

      printingItem = printingItem + 1; // sumo el titulo anterior

      glOverTimeArray.map((employee) => {
        if (currentPage === 1 && printingItem > 20) {
          currentPage++;
          printingItem = 1;
          pdf.addPage();
        } else if (printingItem > 34) {
          currentPage++;
          printingItem = 1;
          pdf.addPage();
        }
        if (currentPage === 1) {
          yPosition = stInitialYPosition + (printingItem - 1) * cellHeight;
        } else {
          yPosition = 18 + (printingItem - 1) * cellHeight;
        }

        printingItem = printingItem + 1;

        writeText(
          pdf,
          employee.name_employee,
          8,
          blackColor,
          whiteColor,
          pageWidth / 5,
          yPosition
        );
        writeText(
          pdf,
          employee.overtime.toFixed(2).toString(),
          10,
          blackColor,
          whiteColor,
          (3.3 * pageWidth) / 7 - 3,
          yPosition,
          "right"
        );
      });
      let glOtTotalPosition = yPosition + 8;
      drawRectangle(
        pdf,
        lighterOrange,
        sideMargin,
        glOtTotalPosition - 5,
        pageWidth - sideMargin * 2,
        cellHeight
      );
      writeText(
        pdf,
        "Total OverTime GL",
        10,
        blackColor,
        whiteColor,
        pageWidth / 5,
        glOtTotalPosition
      );
      writeText(
        pdf,
        project.GL.hours_overtime.toFixed(2).toString(),
        10,
        blackColor,
        whiteColor,
        (3.3 * pageWidth) / 7 - 3,
        glOtTotalPosition,
        "right"
      );
      printingItem = printingItem + 1; // sumo el titulo anterior
      yPosition = glOtTotalPosition;
    }

    /////////////////********///////////////////////// */
    ///Evaluo si queda poco espacio para el pie de pagina
    if (currentPage === 1 && printingItem > 11) {
      pdf.addPage();
      currentPage++;
      yPosition = 0;
    }
    if (printingItem > 26) {
      pdf.addPage();
      currentPage++;
      yPosition = 18;
    } else {
      yPosition = yPosition + 8;
    }
    //////////////////****************************************************** */
    const footerStart = yPosition + 10;

    //left SIDE
    pdf.setFont(undefined, "italic");
    writeText(
      pdf,
      "Thanks for your business!",
      12,
      orangeColor,
      whiteColor,
      pageWidth / 7,
      footerStart
    );

    pdf.setFont(undefined, "normal");

    let dataStart = footerStart + cellHeight;

    footerData.map((line, index) => {
      writeText(
        pdf,
        line,
        8,
        blackColor,
        whiteColor,
        sideMargin,
        dataStart + index * cellHeight
      );
    });

    //Right SIDE
    const rectangleWidth = (pageWidth - sideMargin * 2) / 4;
    const leftRectangleMargin = pageWidth / 2;
    const rightRectangleMargin = (3 * pageWidth) / 4 - sideMargin / 2;
    drawRectangle(
      pdf,
      darkenOrange,
      leftRectangleMargin,
      footerStart - 6,
      rectangleWidth,
      cellHeight * 6
    );
    drawRectangle(
      pdf,
      lighterOrange,
      rightRectangleMargin,
      footerStart - 6,
      rectangleWidth,
      cellHeight * 6
    );

    totalTitles.map((title, index) => {
      writeText(
        pdf,
        title,
        10,
        blackColor,
        darkenOrange,
        leftRectangleMargin + sideMargin,
        footerStart + index * (cellHeight + 2)
      );
    });

    pdf.setFillColor(...lighterOrange);
    pdf.setTextColor(...blackColor); // Color de texto negro
    pdf.setFontSize(10);
    const totalValues = [
      project.TOTAL.grandTotal
        .toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .toString(),
      "-",
      "",
    ];
    totalValues.map((value, index) => {
      pdf.text(
        value,
        pageWidth - sideMargin * 2,
        footerStart + index * (cellHeight + 2),
        {
          align: "right",
        }
      );
    });

    pdf.setFillColor(...whiteColor);

    pdf.text(
      "If you have any questions about this invoice, please contact",
      pageWidth / 2,
      footerStart + 7 * cellHeight,
      {
        align: "center",
      }
    );

    pdf.setTextColor(...orangeColor);
    pdf.text(
      "admin@hcs-solution.com",
      pageWidth / 2,
      footerStart + 8 * cellHeight,
      {
        align: "center",
      }
    );

    pdf.setFont(undefined, "bold");

    writeText(
      pdf,
      "TOTAL",
      14,
      blackColor,
      darkenOrange,
      leftRectangleMargin + sideMargin,
      footerStart + 3 * 10 //3 porque es la longitud de titles array y 10 porque es el cell height de ahroa
    );
    pdf.setFontSize(14);

    pdf.text(
      `$ ${project.TOTAL.grandTotal
        .toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .toString()}`,
      pageWidth - sideMargin * 2,
      footerStart + 3 * (cellHeight + 2),
      {
        align: "right",
      }
    );
    //

    const pagesQty = pdf.internal.getNumberOfPages();
    pdf.setFontSize(8);
    for (let i = 1; i <= pagesQty; i++) {
      pdf.setPage(i);
      pdf.text(`${i} of ${pagesQty}`, pageWidth / 2, pageHeight - 5, {
        align: "center",
      });
    }
    // Guardar el PDF

   pdf.save(
      `Project_${projectTitleData[1]}-Invoice-${invoiceData[0].invoiceNumber}.pdf`
    ); 
  //  const blob = pdf.output("blob");
/* 
    // Crea una URL para el blob
    const url = URL.createObjectURL(blob);

    const windowName = `Project_${projectTitleData[1]}`;
    // Abre una nueva ventana y muestra la vista previa del PDF
    window.open(url, "_blank").name = "NombrePersonalizado"; */
    setProjects([]);

    // window.open(url,windowName,  ["_blank"]);
  };
  useEffect(() => {
    if (facturaData.data) {
      mapProjectsToPDF();
    }
  }, [facturaData.data]);

  return (
    <>
      <Navbar />
      <TopBar />
      <section className="report create shape" style={{ gridRow: "2/4" }}>
        <section className="content content_report">
          <h2>Invoices</h2>

          <DatePick
            setStart={setStart}
            setEnd={setEnd}
            day={invoiceType === "day"}
          />
          {!sending ? (
            <MyButton
              text="Get Projects"
              className="add mb-10"
              onClick={() => {
                getProjectsToCheck();
              }}
            />
          ) : (
            <CircularProgress color="primary" sx={{ alignSelf: "center" }} />
          )}

          <MyButton text="Go Back" className="logout" onClick={() => {}} />
          <section className="selectors" style={{ marginTop: "2rem" }}>
            <div className="check">
              <input
                type="radio"
                name="invoiceType"
                value="week"
                checked={invoiceType === "week"}
                onChange={handleInvoiceType}
              />
              <label> Weekly Invoice</label>
            </div>
            <div className="check">
              <input
                type="radio"
                name="invoiceType"
                value="day"
                onChange={handleInvoiceType}
                checked={invoiceType === "day"}
              />

              <label> Daily Invoice</label>
            </div>
          </section>
        </section>
        <section className="pt_invoice">
          {projects.length > 0 && (
            <>
              <h2>Type the starting invoice number</h2>
              <TextField
                className="input_form"
                id="invoice_number"
                label="Ex. 1000"
                variant="outlined"
                value={invoiceNumber}
                onChange={(e) => {
                  setInvoiceNumber(e.target.value);
                }}
              />
              <h2>Select projects to invoice</h2>
              <div className="check_project shape">
                <input
                  type="checkbox"
                  id="select_all"
                  onChange={selectAllProjects}
                />
                <h5 className="title">"Select All"</h5>
              </div>
              <div className="check_container">
                {projects.map((proyecto) => {
                  return (
                    <div key={proyecto.id} className="check_project shape">
                      <input
                        type="checkbox"
                        checked={projectCheckboxes[proyecto.id] || false}
                        id={proyecto.id}
                        onChange={(e) => {
                          setProjectsArray(e);
                          setProjectCheckboxes((prevCheckboxes) => ({
                            ...prevCheckboxes,
                            [proyecto.id]: e.target.checked,
                          }));
                        }}
                      />
                      <h5 className="title">{proyecto.project_name}</h5>
                    </div>
                  );
                })}
              </div>

              {!sending ? (
                <MyButton
                  text="Get Invoice"
                  className={`add mb-10`}
                  onClick={() => {
                    setError({
                      status: false,
                      message: "",
                      severity: "",
                    });
                    if (projectsToInvoice.length > 0 || invoiceNumber !== "") {
                      getInvoices();
                    } else {
                      setError({
                        status: true,
                        message:
                          "You must type the invoice number and select at least one project",
                        severity: "error",
                      });
                      return;
                    }
                  }}
                />
              ) : (
                <CircularProgress
                  color="primary"
                  sx={{ alignSelf: "center" }}
                />
              )}
            </>
          )}
        </section>
        {error.status && (
          <Alert
            //  className="project_alert"
            severity={error.severity}
            sx={{ marginTop: "1rem" }}
          >
            {error.message}
          </Alert>
        )}
      </section>
    </>
  );
};

export default FullInvoice;
