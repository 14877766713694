import axios from "axios";
import { URL } from "../helpers/general";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { authTypes } from "../types/AuthTypes";

const useQuery = () => {
  const { dispatch } = useContext(AuthContext);
  const postData = async (endpoint, data, token, url_destino) => {
    let auth;
    let response;
    const post_url = `${URL}/${endpoint}`;
    if (token) {
      auth = {
        authorization: `Bearer ${token}`,
      };
      await axios
        .post(post_url, data, { headers: auth })
        .then((res) => {
          response = { data: res, error: false };
        })
        .catch((err) => {
          if(err.response.status===403){
            dispatch({ type: authTypes.logout });
          }
          response = { data: err, error: true };
        });
    } else {
      await axios
        .post(post_url, data)
        .then((res) => {
          response = { data: res, error: false };
        })
        .catch((err) => {
          if(err.response.status===403){
            dispatch({ type: authTypes.logout });
          }
          response = { data: err, error: true };
        });
    }

    return response;
  };

  const getData = async (endpoint, token, url_destino) => {
    let response;

    const post_url = `${URL}/${endpoint}`;

    const auth = {
      authorization: `Bearer ${token}`,
    };
    await axios
      .get(post_url, { headers: auth })
      .then((res) => (response = { data: res, error: false }))
      .catch((err) => {
        if(err.response.status===403){
          dispatch({ type: authTypes.logout });
        }
        response = { data: err, error: true };
      });
    return response;
  };

  const putData = async (endpoint, data, token, url_destino) => {
    let response;

    const post_url = `${URL}/${endpoint}`;

    const auth = {
      authorization: `Bearer ${token}`,
    };
    await axios
      .put(post_url, data, { headers: auth })
      .then((res) => (response = { data: res, error: false }))
      .catch((err) => {
        if(err.response.status===403){
          dispatch({ type: authTypes.logout });
        }
        response = { data: err, error: true };
      });
    return response;
  };

  const deleteData = async (endpoint, datos, token) => {
    let response;
    const post_url = `${URL}/${endpoint}`;
    const auth = {
      authorization: `Bearer ${token}`,
    };
    await axios
      .delete(post_url, { data: datos, headers: auth })
      .then((res) => (response = { data: res, error: false }))
      .catch((err) => {
        response = { data: err, error: true };
      });
    return response;
  };

  return {
    postData,
    getData,
    putData,
    deleteData,
  };
};

export default useQuery;
