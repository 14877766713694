import { useNavigate, useParams } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"; /*  */
import Navbar from "../components/Navbar";
import { useEffect, useState } from "react";
import { Alert, Divider, IconButton } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import TodayIcon from "@mui/icons-material/Today";
import PersonIcon from "@mui/icons-material/Person";
import AddEmployee from "../components/AddEmployee";
import EmployeesGrid from "../components/EmployeesGrid";
import EditEmployee from "../components/EditEmployee";
import useQuery from "../hooks/useQuery";
import { dateToAMD, getDate } from "../helpers/general";
import TopBar from "../components/TopBar";
import Skeleton from "@mui/material/Skeleton";
import MyButton from "../components/MyButton";
import generatePDF, { Margin } from "react-to-pdf";
import * as XLSX from "xlsx";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Tooltip from "@mui/material/Tooltip";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ReportByDate from "../components/ReportByDate";
import CloseIcon from "@mui/icons-material/Close";

const Project = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState([]);
  const [projectManager, setProjectManager] = useState("");
  const [projectSupervisor, setProjectSupervisor] = useState("");
  const [supervisores, setSupervisores] = useState([]);
  const [attendanceId, setAttendanceId] = useState("");
  const [pdf, setPDF] = useState(false);
  const [requiredDate, setRequiredDate] = useState("");
  const [error, setError] = useState({
    status: false,
    message: "",
    severity: "",
  });
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [attendances, setAttendances] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reportByDate, setReportByDate] = useState(false);

  const { getData } = useQuery();

  const setDate = (e) => {
    const newDate = dateToAMD(e.$d);
    const local_date = localStorage.projects_dates || null;
    const newKey = `project_${id}`;
    setRequiredDate(newDate);
    if (!local_date) {
      const new_object = { [newKey]: newDate };
      localStorage.setItem(`projects_dates`, JSON.stringify(new_object));
    } else {
      const new_object = { ...JSON.parse(local_date), [newKey]: newDate };
      localStorage.setItem(`projects_dates`, JSON.stringify(new_object));
    }
    getInitialData();
  };

  const checkManager = (supervisores, project) => {
    const manager = supervisores?.find(
      (supervisor) => supervisor.id === project.id_manager
    );
    const supervisor = supervisores.find(
      (supervisor) => supervisor?.id === project.id_supervisor
    );
    setProjectManager(manager?.complete_name);
    setProjectSupervisor(supervisor?.complete_name);
  };

  const getInitialData = async () => {
    setLoading(true);
    const data = await getData(`projectsById/${id}`, localStorage.token);
    const supervisors = await getData("projectLeaders", localStorage.token);
    console.log(data, supervisors)
    //chequea si hay fecha en LocalStorage o no y la devuelve.
    const req_date = checkDefaultValue();
    setRequiredDate(dateToAMD(req_date));
    const projectData = await getData(
      `employeesProject/${id}/${dateToAMD(req_date)}`,
      localStorage.token
    );
    if (data.error === true || data.data.status===201) {
      navigate("/");
      return;
    }
    setProject(data.data.data[0]);

    if (typeof projectData.data.data.message === "string") {
      //si no hay asistencias
      setAttendances([]);
      setError({
        status: true,
        message: projectData.data.data.message,
        severity: "warning",
      });
    } else {
      setError({ status: false, message: "", severity: "" });
      //si hay asistencias
      setAttendances(projectData.data.data);
    }
    setSupervisores(supervisors.data.data);
    setLoading(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);
  useEffect(() => {
    getInitialData();
  }, [edit, add]);

  useEffect(() => {
    checkManager(supervisores, project);
  }, [project, supervisores]);

  const checkDefaultValue = () => {
    if (localStorage.projects_dates) {
      const value = JSON.parse(localStorage.projects_dates);
      const dateValue = value[`project_${id}`];
      return dayjs(dateValue);
    } else {
      return dayjs(dateToAMD(getDate()));
    }
  };

  const downloadPDF = () => {
    const date = getDate();
    const pn = project.project_number;
    generatePDF(() => document.getElementById("content-id"), {
      method: "save",
      filename: `${date}_${pn}.pdf`,
      page: { margin: Margin.MEDIUM },
    });
  };
  const downloadExcel = () => {
    if (attendances.length === 0) {
      return;
    }
    const rows = attendances.map((attendance) => ({
      date: getDate(),
      project_number: project.project_number,
      project_name: project.project_name,
      name: attendance.name_employee,
      code: attendance.cod,
      start: attendance.start,
      end: attendance.end,
      breaks: attendance.break_time,
      total: attendance.total_time,
    }));
    /*   const info = [{
    date: "Date",
    project: "Project Number",
    name: "Name",
    code: "Code",
    start:"Start Time",
    end: "End Time",
    breaks: "Breaks",
    total: "Total"
    }]
    const newA = info.concat(rows)
    console.log(attendances) */

    // create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rows);

    XLSX.utils.book_append_sheet(workbook, worksheet, `Attendances`);

    // customize header names
    XLSX.utils.sheet_add_aoa(worksheet, [
      [
        "Date",
        "Project Number",
        "Project Name",
        "Name",
        "Code",
        "Start Time",
        "End Time",
        "Breaks",
        "Total",
      ],
    ]);

    XLSX.writeFile(
      workbook,
      `Project_${project.project_name}_${getDate()}.xlsx`,
      { compression: true }
    );
  };

  return (
    <>
      <TopBar />
      <Navbar />

      <section className="project_body">
        <section className="project_header ">
          <h1 className="project_header-name">
            {loading ? (
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            ) : (
              <>
                {" "}
                {project.project_name} # {project.project_number}
              </>
            )}
          </h1>
          <h2 className="project_header-manager">
            <PersonIcon />
            <span>Manager </span>

            {loading ? (
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            ) : (
              <> {projectManager}</>
            )}
          </h2>
          <h2 className="project_header-super">
            <PersonOutlineIcon />
            <span> Supervisor</span>
            {loading ? (
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            ) : (
              <>{projectSupervisor}</>
            )}
          </h2>
          <h2 className="project_header-location">
            <LocationOnIcon />
            <span> Location </span>{" "}
            {loading ? (
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            ) : (
              <>{project.location}</>
            )}
          </h2>
          <h2 className="project_header project_header-date">
            <TodayIcon />
            <span> Pick a date </span>{" "}
            <DatePicker
              className="date_picker"
              onChange={setDate}
              defaultValue={checkDefaultValue()}
            />
          </h2>
        </section>
        <section className="dl">
          <Tooltip
            title={
              attendances?.length === 0 ? "No attendances" : "Download to Excel"
            }
          >
            <FileDownloadIcon
              onClick={downloadExcel}
              className={attendances?.length === 0 ? "locked" : ""}
            />
          </Tooltip>
          <Tooltip
            title={
              attendances?.length === 0 ? "No attendances" : "Download to PDF"
            }
          >
            <PictureAsPdfIcon
              onClick={() => {
                if (attendances.length === 0) {
                  return;
                }
                setPDF(true);
              }}
              className={attendances?.length === 0 ? "locked" : ""}
            />
          </Tooltip>
          <Tooltip title="Report by date">
            <DateRangeIcon onClick={() => setReportByDate(true)} />
          </Tooltip>
        </section>
        <MyButton
          className={`add ${add || edit ? "hidden" : ""} employee`}
          text="Add Employee"
          onClick={() => {
            setError({
              status: false,
              message: "",
              severity: "",
            });
            setAdd(true);
          }}
        />
      </section>
      {error.status && (
        <Alert
          className={`project_alert ${
            error.severity === "warning" ? "warning" : ""
          } ${error.severity === "warning" && add ? "hidden" : ""}`}
          severity={error.severity}
          sx={{ marginTop: "1rem" }}
          action={
          error.severity==="warning" &&
          <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            setError({
              status: false,
              message: "",
              severity: "",
            });
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
          }
        >
          {error.message}
        </Alert>
      )}
      <>
        {" "}
        {add && (
          <AddEmployee
            setAdd={setAdd}
            attendances={attendances}
            setAttendances={setAttendances}
            setError={setError}
          />
        )}
        {edit && (
          <EditEmployee
            setEdit={setEdit}
            attendanceId={attendanceId}
            setError={setError}
          />
        )}
      </>

      {!add && !edit && !reportByDate && attendances && (
        <section className="show_employees">
          <>
            <EmployeesGrid
              add={add}
              attendances={attendances}
              setEdit={setEdit}
              setEmployeeId={setEmployeeId}
              setAttendanceId={setAttendanceId}
              setError={setError}
              loading={loading}
              getInitialData={getInitialData}
              setLoading={setLoading}
            />
          </>
        </section>
      )}
      <>
        {reportByDate && (
          <ReportByDate
            projectId={id}
            setReportByDate={setReportByDate}
            type="byProject"
          />
        )}
      </>
      <>
        {pdf && (
          <section className="pdf">
            <section className="pdf_content" id="content-id" title="test">
              <img
                src="../assets/logo.png"
                width="150px"
                style={{ marginTop: "2rem" }}
              />
              <section className="data">
                <section className="pdf_header">
                  <h4>
                    Project: {project.project_name} - #{project.project_number}
                  </h4>
                  <h4 className="pdf_date">Date : {requiredDate}</h4>
                  <h4>Manager : {projectManager}</h4>
                  <span></span>
                  <h4>Supervisor : {projectSupervisor}</h4>

                  <h4></h4>
                  <h4>Location : {project.location}</h4>
                </section>
                <Divider />
                <section className="info">
                  <h2 className="header_title">Name</h2>
                  <h2 className="header_title">Code</h2>
                  <h2 className="header_title">Start</h2>
                  <h2 className="header_title">End</h2>
                  <h2 className="header_title">Breaks</h2>
                  <h2 className="header_title">Total</h2>
                  <h2 className="header_title">Signature</h2>
                </section>

                {attendances.map((data) => {
                  return (
                    <section className="info" key={attendances.indexOf(data)}>
                      {loading ? (
                        <Skeleton
                          variant="text"
                          sx={{
                            fontSize: "1rem",
                            height: "30px",
                            alignSelf: "center",
                            padding: "0 5px",
                          }}
                        />
                      ) : (
                        <h2 className="info_name">{data.name_employee}</h2>
                      )}
                      {loading ? (
                        <Skeleton
                          variant="text"
                          sx={{
                            fontSize: "1rem",
                            height: "30px",
                            alignSelf: "center",
                            padding: "0 5px",
                          }}
                        />
                      ) : (
                        <h3>{data.cod}</h3>
                      )}
                      {loading ? (
                        <Skeleton
                          variant="text"
                          sx={{
                            fontSize: "1rem",
                            height: "30px",
                            alignSelf: "center",
                            padding: "0 5px",
                          }}
                        />
                      ) : (
                        <h3>{data.start}</h3>
                      )}
                      {loading ? (
                        <Skeleton
                          variant="text"
                          sx={{
                            fontSize: "1rem",
                            height: "30px",
                            alignSelf: "center",
                            padding: "0 5px",
                          }}
                        />
                      ) : (
                        <h3>{data.end !== "00:00:00" ? data.end : "" || ""}</h3>
                      )}
                      {loading ? (
                        <Skeleton
                          variant="text"
                          sx={{
                            fontSize: "1rem",
                            height: "30px",
                            alignSelf: "center",
                            padding: "0 5px",
                          }}
                        />
                      ) : (
                        <h3>
                          {data.break_time !== "00:00:00"
                            ? data.break_time
                            : "" || ""}
                        </h3>
                      )}
                      {loading ? (
                        <Skeleton
                          variant="text"
                          sx={{
                            fontSize: "1rem",
                            height: "30px",
                            alignSelf: "center",
                            padding: "0 5px",
                          }}
                        />
                      ) : (
                        <h3>{data.total_time || ""}</h3>
                      )}
                      {loading ? (
                        <Skeleton
                          variant="text"
                          sx={{
                            fontSize: "1rem",
                            height: "30px",
                            alignSelf: "center",
                            padding: "0 5px",
                          }}
                        />
                      ) : (
                        <img
                          src={`../${data.signature_employee}`}
                          className="sign"
                        />
                      )}
                    </section>
                  );
                })}
              </section>
            </section>
            <section className="buttons_pdf">
              <MyButton
                className="logout"
                text="CANCEL"
                onClick={() => {
                  setPDF(false);
                }}
              />
              <MyButton className="add" text="DOWNLOAD" onClick={downloadPDF} />
            </section>
          </section>
        )}
      </>
    </>
  );
};

export default Project;
