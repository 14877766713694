import {  Routes, Route } from "react-router-dom";
import Home from "../views/Home";
import Project from "../views/Project";
import Register from "../views/Register";
import CreateUser from "../views/Create";
import AddNewProject from "../components/AddNewProject";
import DeleteUser from "../views/DeleteUser";
import EditProject from "../views/EditProject";
import FullReport from "../views/FullReport";
import FullInvoice from "../components/FullInvoice";
import PdfAttendanceReport from "../views/PdfAttendanceReport";

const AppRouter = () => {
  return (
        <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/register" element={<Register />}/>
            <Route path="/report" element={<FullReport />}/>
            <Route path="/attendance_report" element={<PdfAttendanceReport />}/>
            <Route path="/create_project" element={<AddNewProject />}/>
            <Route path="/edit_project/:id" element={<EditProject />}/>
            <Route path="/create" element={<CreateUser />}/>
            <Route path="/invoices" element={<FullInvoice />}/>
            <Route path="/delete_user" element={<DeleteUser />}/>

            <Route path="/projects/:id" element={<Project/>} />
        </Routes>
  )
}

export default AppRouter