import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControl,
} from "@mui/material";
import { employeeTypes } from "../helpers/data";
import { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import SignaturePad from "./SignaturePad";
import { checkStartTime, dateToAMD, getDate } from "../helpers/general";
import useQuery from "../hooks/useQuery";
import MyButton from "./MyButton";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { InputReducer } from "../reducers/InputReducer";

const AddEmployee = (props) => {
  const [sending, setSending] = useState(false);
  const [sign, setSign] = useState(false);
  const { getData, postData } = useQuery();
  const [previousEmployees, setPrevEmployees] = useState([]);

  const { setAdd, attendance, setAttendances, setError } = props;
  const { id } = useParams();

  const validateInput = (e) => {
    if (e.target.id === "start-time") {
      let status = checkStartTime(inputData.start_time);
      if (status === false) {
        inputDispatch({
          type: "CHANGE_FIELD",
          fieldName: "start_time_valid",
          fieldValue: null,
        });
        // setStartTime({ ...startTime, status: null });
      } else {
        inputDispatch({
          type: "CHANGE_FIELD",
          fieldName: "start_time_valid",
          fieldValue: true,
        });
        // setStartTime({ ...startTime, status: true });
      }
    }
  };

  const addEmployee = async () => {
    setError({ severity: "", message: "", status: false });
    let attendance_date;
    if (localStorage.projects_dates) {
      const value = JSON.parse(localStorage.projects_dates);
      attendance_date = value[`project_${id}`];
    }
    if (attendance_date === undefined) {
      attendance_date = dateToAMD(getDate());
    }
    
    if (
      inputData.name_employee === "" ||
      inputData.signature_employee === "" ||
      inputData.id_code === "" ||
      inputData.start_time === "" ||
      !attendance_date
    ) {
      setError({
        status: true,
        message: "You must fill all the fields",
        severity: "error",
      });
      return;
    }
    setSending(true);
    const employeeData = {
      id_project: id,
      name_employee: inputData.name_employee,
      id_emp: inputData.id_emp || null,
      signature_employee: inputData.signature_employee,
      id_code: inputData.id_code,
      start: inputData.start_time,
      attendance_date,
    };
    console.log(employeeData);
    const data = await postData(
      "employeeAttendance",
      employeeData,
      localStorage.token
    );

    if (data.error === false) {
      if (data.data.status === 201) {
        setError({
          status: true,
          message:
            "The employee already exists in this day's project assistance",
          severity: "error",
        });
        setSending(false);
      } else {
        setAdd(false);
      }
      return;
    }

    setError({ status: true, message: "Error inesperado", severity: "error" });
    setSending(false);
  };

  // Función para eliminar duplicados basados en la propiedad id_employee
const deleteDuplicated = (array, propiedad) => {
  return array.filter((obj, index, self) =>
    index === self.findIndex((o) => o[propiedad] === obj[propiedad])
  );
}

// Filtrar el array de objetos


  const getAttendance = async () => {
    const previousEmployees = await getData(
      `employeesProjectTotal/${id}`,
      localStorage.token
    );
    console.log(previousEmployees.data)
    if (typeof previousEmployees.data.data.message === "string") {
      setPrevEmployees([]);
      return;
    }
    let filtered = deleteDuplicated(previousEmployees.data.data, 'id_employee');
    setPrevEmployees(filtered);
  };

  useEffect(() => {
    getAttendance();
  }, []);
  const filter = createFilterOptions();
  const [inputData, inputDispatch] = useReducer(InputReducer, {
    name_employee: "",
    start_time: "",
    start_time_valid: null,
    id_code: "",
    signature_employee: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    inputDispatch({
      type: "CHANGE_FIELD",
      fieldName: name,
      fieldValue: value,
    });
  };
  return (
    <>
      <section className="create shape">
        {!sign ? (
          <>
            <h3>Register employee assistance</h3>
            <p>Please fill all the fields below</p>

            <Autocomplete
              value={inputData.name_employee}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  inputDispatch({
                    type: "CHANGE_FIELD",
                    fieldName: "name_employee",
                    fieldValue: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  // setName(newValue.inputValue);
                  inputDispatch({
                    type: "CHANGE_FIELD",
                    fieldName: "name_employee",
                    fieldValue: newValue.inputValue,
                  });
                  inputDispatch({
                    type: "CHANGE_FIELD",
                    fieldName: "signature_employee",
                    fieldValue: "",
                  });
                } else {
                  if (!newValue) {
                    inputDispatch({
                      type: "CHANGE_FIELD",
                      fieldName: "name_employee",
                      fieldValue: "",
                    });
                    inputDispatch({
                      type: "CHANGE_FIELD",
                      fieldName: "id_emp",
                      fieldValue: null,
                    });
                    inputDispatch({
                      type: "CHANGE_FIELD",
                      fieldName: "signature_employee",
                      fieldValue: "",
                    });

                    return;
                  }
                  inputDispatch({
                    type: "CHANGE_FIELD",
                    fieldName: "name_employee",
                    fieldValue: newValue.name_employee,
                  });
                  inputDispatch({
                    type: "CHANGE_FIELD",
                    fieldName: "id_emp",
                    fieldValue: newValue.id_employee,
                  });
                  inputDispatch({
                    type: "CHANGE_FIELD",
                    fieldName: "signature_employee",
                    fieldValue: `../${newValue.signature_employee}`,
                  });
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.name_employee
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    name_employee: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="name_employee"
              options={previousEmployees}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name_employee;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.name_employee}</li>
              )}
              // sx={{ width: 300 }}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="e.g. John Doe" />
              )}
            />

            <TextField
              error={!inputData.start_time_valid}
              id="start-time"
              label="Start time (e.g. 0900)"
              variant="outlined"
              className="input_form"
              name="start_time"
              onChange={handleInputChange}
              onKeyUp={validateInput}
              value={inputData.start_time || ""}
            />
            <FormControl fullWidth className="input_form">
              <InputLabel id="employee-type">Select employee code</InputLabel>
              <Select
                labelId="employee-type"
                id="employee-type"
                name="id_code"
                onChange={handleInputChange}
                value={inputData.id_code || ""}
              >
                {employeeTypes.map((persona) => {
                  return (
                    <MenuItem value={persona.id} key={persona.type}>
                      {persona.type}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              onClick={() => {
                setSign(true);
              }}
              sx={{ marginBottom: "1rem" }}
            >
              Employee Signature
            </Button>
            {!sending ? (
              <MyButton text="Set" onClick={addEmployee} className="action" />
            ) : (
              <CircularProgress color="primary" sx={{ alignSelf: "center" }} />
            )}
          </>
        ) : (
          <>
            <SignaturePad
              setSign={setSign}
              signImg={inputData.signature_employee}
              //  setSignImg={setSignImg}
              inputDispatch={inputDispatch}
            />
          </>
        )}
      </section>
    </>
  );
};

export default AddEmployee;
